<template>
  <div class="col-12">
    <Card>
      <template #title>Busca por servidores</template>
      <template #content>
        <form @submit.prevent="onFilter()">
          <div class="flex flex-wrap">
            <div class="col-12 md:col-4 pb-0">
              <InputText
                id="servidorInput"
                v-model="filters.matriculaCpf.value"
                class="w-full"
                :class="servidorNaoConsentiu ? 'p-invalid' : ''"
                type="text"
                placeholder="CPF, Matrícula ou E-mail"
                autofocus />
            </div>
            <div
              v-if="servidorNaoConsentiu"
              class="erro flex md:pl-2 md:flex-none md:flex-order-2 w-full justify-content-center md:justify-content-start md:flex-nowrap">
              <span class="text-sm p-error">
                {{ mensagemServidorNaoConsentiu }}
              </span>
            </div>
            <div
              class="col-12 md:col-4 pb-0 flex justify-content-center md:justify-content-start">
              <Button
                id="btn-limparBuscarServidor"
                class="p-button-outlined mr-2"
                icon="pi pi-filter-slash"
                label="Limpar"
                @click="clearFilters" />
              <Button
                id="btn-buscarServidor"
                :icon="loading ? 'pi pi-spin pi-spinner' : 'pi pi-search'"
                :label="loading ? 'Carregando...' : 'Buscar'"
                @click="onFilter()" />
            </div>
          </div>
        </form>
        <DataTable
          v-if="exibeTabela"
          class="mt-3"
          :value="servidores"
          stripedRows
          :paginator="true"
          :rows="10"
          :loading="loading"
          :totalRecords="totalRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} servidores"
          responsiveLayout="scroll">
          <template #empty> Nenhum servidor encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column field="cpf" header="CPF">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.cpf : '-' }}
            </template>
          </Column>
          <Column field="nome" header="Nome">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.nome : '-' }}
            </template>
          </Column>
          <Column field="matricula" header="Matrícula">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.matricula : '-' }}
            </template>
          </Column>
          <Column field="entidadeNome" header="Entidade">
            <template #body="{ data }">
              {{ data.hasConsentimentoConsignataria ? data.entidadeNome : '-' }}
            </template>
          </Column>
          <Column field="vinculoNome" header="Vínculo">
            <template #body="{ data }">
              <span
                v-if="
                  data.vinculoNome === 'PENSIONISTA' ||
                  data.vinculoNome === 'APOSENTADO' ||
                  data.vinculoNome === 'EFETIVO'
                "
                style="color: green">
                {{
                  data.hasConsentimentoConsignataria ? data.vinculoNome : '-'
                }}
              </span>
              <span v-else style="color: red">
                {{
                  data.hasConsentimentoConsignataria ? data.vinculoNome : '-'
                }}
              </span>
            </template>
          </Column>
          <Column header="Situação">
            <template #body="{ data }">
              <span v-if="data.hasConsentimentoConsignataria">
                <Tag
                  v-tooltip.bottom="
                    data.validacao.servidorApto
                      ? 'O servidor está apto a consignar.'
                      : 'O servidor está inapto a consignar pelo motivo(s): ' +
                        exibirMotivosInapto(data.validacao.motivos)
                  "
                  :value="
                    data.validacao.servidorApto
                      ? 'Apto a consignar'
                      : 'Inapto a consignar: ' +
                        exibirMotivosInapto(data.validacao.motivos)
                  "
                  :severity="data.validacao.severity">
                </Tag
              ></span>
            </template>
          </Column>
          <Column header="Ações">
            <template #body="{ data }">
              <Button
                v-if="data.hasConsentimentoConsignataria"
                v-tooltip.left="'Detalhes'"
                class="p-button-rounded"
                :icon="loadingDetail ? 'pi pi-spin pi-spinner' : 'pi pi-eye'"
                @click="detalheServidor(data)">
              </Button>
            </template>
          </Column>
        </DataTable>
        <Tag
          v-show="exibeTabelaPendente"
          class="mt-3"
          severity="warning"
          value="Há servidores com portabilidade pendente!"
          :style="{ width: '100%', fontSize: '18px' }">
        </Tag>
        <DataTable
          v-show="exibeTabelaPendente"
          class="mt-3"
          :value="servidoresPendentes"
          stripedRows
          :paginator="true"
          :rows="10"
          :loading="loading"
          :totalRecords="totalRecords"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} servidores"
          responsiveLayout="scroll">
          <template #empty> Nenhum servidor encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column header="Cadastrado Em">
            <template #body="{ data }">
              {{ data.proposta.auditMetadata.cadastradoEm | formatarData }}
            </template>
          </Column>
          <Column field="proposta.matricula.servidor.cpf" header="CPF"></Column>
          <Column
            field="proposta.matricula.matricula"
            header="Matrícula"></Column>
          <Column
            field="proposta.matricula.servidor.nome"
            header="Nome"></Column>
          <Column header="Agência">
            <template #body="{ data }">
              {{ data.proposta.agencia ? data.proposta.agencia : '-' }}
            </template>
          </Column>
          <Column
            field="proposta.matricula.entidade.nome"
            header="Entidade"></Column>
          <Column
            :sortable="true"
            field="proposta.statusProposta.nome"
            header="Situação">
            <template #body="{ data }">
              <Chip
                v-if="data.proposta.statusProposta.nome === 'Pendente'"
                label="Pendente"
                class="mr-2 mb-2 custom-chip pendente" />
            </template>
          </Column>
        </DataTable>
      </template>

      <Dialog
        modal
        header="Header"
        :style="{ width: '350px' }"
        :visible.sync="deleteServidorDialog">
        <template #header>
          <h3>Excluir Servidor</h3>
        </template>

        Confirma a exclusão de {{ servidor.nome }} ?

        <template #footer>
          <Button
            label="Não"
            icon="pi pi-times"
            class="p-button-text"
            @click="deleteServidorDialog = false" />
          <Button
            label="Sim"
            icon="pi pi-check"
            autofocus
            @click="deleteServidor" />
        </template>
      </Dialog>
    </Card>
  </div>
</template>

<script>
import moment from 'moment'
import { watch } from 'vue-demi'
import MatriculaService from '@/service/MatriculaService.js'
import { consignatariaStore } from '@/stores/consignataria'
import CompraDividaService from '@/service/CompraDividaService'

export default {
  setup() {
    const store = consignatariaStore()
    return { store }
  },

  data() {
    return {
      servidores: null,
      servidor: {},
      servidoresPendentes: [],
      deleteServidorDialog: false,
      exibeTabela: false,
      loading: false,
      totalRecords: 0,
      parametros: {},
      filters: {
        matriculaCpf: { value: null, matchMode: 'exactly' },
      },
      loadingDetail: false,
      exibeTabelaPendente: false,
      servidorNaoConsentiu: false,
      mensagemServidorNaoConsentiu:
        'O servidor não consentiu acesso dos dados à consignatária.',
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.compraDividaService = new CompraDividaService(this.$http)
    watch(
      () => this.store.consignatariaSelecionada,
      () => {
        this.clearFilters()
        this.carregarServidoresPendentes()
      },
      { deep: true },
    )
  },

  mounted() {
    this.parametros = {
      first: 0,
      page: 0,
      filters: this.filters,
      consignatariaSelecionada: 0,
    }
    this.carregarServidoresPendentes()
  },

  methods: {
    detalheServidor(data) {
      this.loadingDetail = true
      this.$router.push({ name: 'servidores-margem', params: { id: data.id } })
      this.loadingDetail = false
    },

    onPage(event) {
      this.parametros = event
      this.carregarDadosFiltrados()
    },

    onFilter() {
      if (!this.store.consignatariaSelecionada) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Selecione a consignatária.',
          life: 10000,
        })
        return
      }

      if (this.filters.matriculaCpf.value) {
        this.parametros.filters = this.filters
        this.carregarDadosFiltrados()
      } else {
        this.$toast.add({
          severity: 'warn',
          summary: 'Informe o CPF ou a matrícula.',
          life: 10000,
        })
      }
    },

    carregarDadosFiltrados() {
      this.parametros.consignatariaSelecionada =
        this.store.consignatariaSelecionada.id
      this.servidorNaoConsentiu = false
      this.loading = true
      this.matriculaService
        .getMatriculasPelaConsignataria(this.parametros)
        .then((res) => {
          this.servidores = res
          this.totalRecords = res.length
          if (!this.servidores[0].hasConsentimentoConsignataria) {
            this.exibeTabela = false
            this.servidorNaoConsentiu = true
            this.$toast.add({
              severity: 'error',
              summary: this.mensagemServidorNaoConsentiu,
              life: 5000,
            })
          } else this.exibeTabela = true
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    carregarServidoresPendentes() {
      if (
        !this.store.consignatariaSelecionada ||
        !this.store.consignatariaSelecionada.id
      ) {
        this.loading = false
        return
      }
      this.loading = true
      this.parametros.consignatariaSelecionada =
        this.store.consignatariaSelecionada.id
      this.compraDividaService
        .getComprasDividasPorConsignatariaVendidoPendente(
          this.parametros.consignatariaSelecionada,
        )
        .then((res) => {
          this.servidoresPendentes = res
          this.totalRecords = res.length
          this.loading = false
          this.exibeTabelaPendente = this.servidoresPendentes.length > 0
        })
        .catch((err) => {
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response
              ? err.response.data.message
              : 'Error fetching data',
            life: 10000,
          })
        })
    },

    clearFilters() {
      this.filters.matriculaCpf.value = null
      this.servidores = null
      this.exibeTabela = false
    },

    formatarData(value) {
      if (value == null || typeof value === 'undefined' || value == '') {
        return '-'
      } else {
        this.data = moment(value).format('DD/MM/YYYY')
        return this.data
      }
    },

    exibirMotivosInapto(arrayMotivos) {
      return arrayMotivos.length > 1 ? arrayMotivos[1] : arrayMotivos[0]
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
</style>
